import React from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import {
  ArticleFeatureCard,
  BlogKit,
  Articles,
  ArticleCard,
  Sidebar,
  Categories,
  LinkTo,
} from '@dragonchain-dev/dragon-kit';
import Layout from '../components/Layout';
import { SEO } from '../components/_index';

const Blog = ({ data: { allMdx }, pageContext: { pagination, categories, activeCategory } }) => {
  const { IMAGES_URI } = process.env;
  const { page, nextPagePath, previousPagePath } = pagination;
  const posts = page.map(id => allMdx.edges.find(edge => edge.node.id === id));

  const features =
    !activeCategory &&
    !nextPagePath &&
    posts.slice(0, 5).map(({ node: post }) => {
      const { slug, categories: cat, title, publishDate, featureImage } = post.frontmatter;
      const image = featureImage
        ? `${featureImage.childImageSharp.fluid.src}`
        : `${IMAGES_URI}/articles/default-banner-large.png`;
      return (
        <ArticleFeatureCard
          key={post.id}
          url={`/blog/${slug}`}
          category={cat[0]}
          title={title}
          image={image}
          publishDate={publishDate}
        />
      );
    });

  if (!activeCategory && !nextPagePath) posts.splice(0, 5);
  const title = activeCategory
    ? `${activeCategory} Articles | Dragonchain Blockchain Blog`
    : 'Blockchain Blog | Dragonchain';
  return (
    <Layout>
      <SEO
        title={title}
        description="The Dragonchain blockchain blog captures the most important updates on enterprise blockchain, Bitcoin, Ethereum, and other blockchain technologies. Read news, interviews, conversations, and opinions from the blockchain community."
      />
      <BlogKit
        category={activeCategory}
        features={features}
        title="Blockchain Blog"
        theme="light"
        url="/blog"
      >
        <Articles previous={previousPagePath} next={nextPagePath}>
          {posts.map(({ node: post }) => {
            const featureImage = post.frontmatter.featureImage
              ? post.frontmatter.featureImage.childImageSharp.fluid
              : undefined;
            return featureImage ? (
              <div className="blog-article-card">
                <div className="inner">
                  <LinkTo type="route" url={`/blog/${post.frontmatter.slug}`}>
                    <div className="image">
                      <div className="inner">
                        <Img loading="eager" fadeIn={false} fluid={featureImage} />
                      </div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <time dateTime={post.frontmatter.publishDate}>{post.frontmatter.publishDate}</time>
                        <div className="category">{post.frontmatter.categories[0]}</div>
                        <h2 className="title">
                          <span>{post.frontmatter.title}</span>
                        </h2>
                      </div>
                    </div>
                  </LinkTo>
                </div>
              </div>
            ) : (
              <ArticleCard
                key={post.id}
                slug={`/${post.frontmatter.slug}`}
                category={post.frontmatter.categories[0]}
                title={post.frontmatter.title}
                image={`${IMAGES_URI}/articles/default-banner@2x.png`}
                publishDate={post.frontmatter.publishDate}
              />
            );
          })}
        </Articles>
        <Sidebar>
          <Categories title="Categories" categories={['Press', ...categories.splice(0, 10)]} />
        </Sidebar>
      </BlogKit>
    </Layout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query {
    allMdx {
      edges {
        node {
          id
          frontmatter {
            categories
            publishDate(formatString: "MMMM DD, YYYY")
            title
            slug
            featureImage {
              ...BlogFeatureImageFragment
            }
          }
        }
      }
    }
  }

  fragment BlogFeatureImageFragment on File {
    childImageSharp {
      fluid(maxWidth: 816) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`;
